<template>
  <div>
    <el-dialog
        title="添加订单"
        width="55%"
        :visible.sync="state"
        :modal-append-to-body="false"
        @close="close"
        center>
        <el-scrollbar style="height:100%">
          <el-steps :active="active" finish-status="success" simple>
            <el-step title="填写订单信息" icon="el-icon-edit"></el-step>
            <el-step title="填写收款信息" icon="el-icon-edit"></el-step>
            <el-step title="添加订单图片" icon="el-icon-picture"></el-step>
            <el-step title="确认订单信息" icon="el-icon-check"></el-step>
            <el-step title="完成" icon="el-icon-finished"></el-step>
          </el-steps>
          <div style="margin-top: 30px" class="el-dialog-div">
            <el-form label-width="100px" :model="step1" :rules="step1Rules" v-show="active===0" ref="step1">
              <el-row>
                <el-col :span="10" :offset="7">
                  <el-form-item label="订单号">
                    <el-input v-model="step1.orderNo" placeholder="请填写订单号"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="10" :offset="7">
                  <el-form-item label="订单日期" prop="createDate">
                    <el-date-picker type="date" placeholder="预约日期"
                                    v-model="step1.createDate"
                                    style="width: 100%;"
                                    value-format="yyyy-MM-dd">
                    </el-date-picker>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="10" :offset="7">
                  <el-form-item label="精确婚期" prop="weddingDay">
                    <el-date-picker type="date" placeholder="精确婚期"
                                    v-model="step1.weddingDay"
                                    style="width: 100%;"
                                    value-format="yyyy-MM-dd">
                    </el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col :span="3" v-if="weddingList.length>1">
                  <el-select v-model="step1.weddingDay" placeholder="选择婚期">
                    <el-option
                        v-for="item in weddingList"
                        :key="item.id"
                        :label="item.weddingDay"
                        :value="item.weddingDay">
                    </el-option>
                  </el-select>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="10" :offset="7">
                  <el-form-item label="订单项目" prop="orderName">
                    <el-select v-model="step1.orderName" placeholder="订单项目" style="width: 100%" clearable ref="orderName" @change="orderNameChange">
                      <el-option
                          v-for="item in orderNameArray"
                          :key="item.value"
                          :label="item.name"
                          :value="item.value">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="10" :offset="7">
                  <el-form-item label="礼服师" prop="orderDress">
                    <el-select v-model="step1.orderDress" placeholder="礼服师" style="width: 100%" clearable>
                      <el-option
                          v-for="item in orderDressArray"
                          :key="item.value"
                          :label="item.name"
                          :value="item.value">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="10" :offset="7">
                  <el-form-item label="辅销">
                    <el-select v-model="step1.orderAuxiliaryDress" placeholder="辅销" style="width: 100%" clearable>
                      <el-option
                          v-for="item in orderDressArray"
                          :key="item.value"
                          :label="item.name"
                          :value="item.value">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="10" :offset="7">
                  <el-form-item label="化妆师" prop="orderCosmetics" :rules="orderCosmeticsCheck ? step1Rules.orderCosmetics : [{ required:false}]">
                    <el-select v-model="step1.orderCosmetics" placeholder="化妆师" style="width: 100%" clearable>
                      <el-option
                          v-for="item in cosmeticsArray"
                          :key="item.value"
                          :label="item.name"
                          :value="item.value">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="10" :offset="7">
                  <el-form-item label="订单备注">
                    <el-input
                        type="textarea"
                        :autosize="{ minRows: 4, maxRows: 4}"
                        placeholder="请输入内容"
                        v-model="step1.orderRemark">
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-form-item style="text-align: center">
                <el-button type="primary" @click="stepNext('step1')">下一步</el-button>
              </el-form-item>
            </el-form>
            <el-form label-width="100px" :model="step2" :rules="step2Rules"  v-show="active===1" ref="step2">
              <el-row>
                <el-col :span="10" :offset="7">
                  <el-form-item label="收款项目" prop="proceedsName">
                    <el-select placeholder="请选择收款项目" v-model="step2.proceedsName" style="width: 100%;">
                      <el-option
                          v-for="item in proceedsNameArray"
                          :key="item.value"
                          :label="item.name"
                          :value="item.value">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="10" :offset="7">
                  <el-form-item label="收款方式" prop="payment">
                    <el-cascader
                        :props="{checkStrictly: true,
                            expandTrigger: 'hover',
                            label: 'name'}"
                        multiple="false"
                        :show-all-levels="false"
                        style="width: 100%;"
                        :options="paymentArray"
                        v-model="step2.payment"
                        ref="payment"
                        @change="cascaderClsoe"></el-cascader>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="10" :offset="7">
                  <el-form-item label="订单总价" prop="orderPrice">
                    <el-input v-model.number="step2.orderPrice" placeholder="订单总价"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="10" :offset="7">
                  <el-form-item label="收款金额" prop="spareMoney">
                    <el-input v-model.number="step2.spareMoney" placeholder="收款金额"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row v-if="onlineOrderArray.length !== 0">
                <el-col :span="10" :offset="7">
                  <el-form-item label="已收金额">
                    <el-input v-model.number="step2.receivedMoney" placeholder="收款金额"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="10" :offset="7">
                  <el-form-item label="余额">
                    <el-input v-model="step2.orderSpare" readonly placeholder="余额"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="10" :offset="7">
                  <el-form-item label="收款人" prop="payee">
                    <el-select placeholder="请选择收款人" v-model="step2.payee" style="width: 100%;" ref="service">
                      <el-option
                          v-for="item in payeeArray"
                          :key="item.value"
                          :label="item.name"
                          :value="item.value">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-form-item style="text-align: center">
                <el-button @click="previous">上一步</el-button>
                <el-button type="primary" @click="stepNext('step2')">下一步</el-button>
              </el-form-item>
              <div v-if="onlineOrderArray.length !== 0">
                <el-divider content-position="left">线上订单收款</el-divider>
                <el-table
                    :data="onlineOrderProceedsArray"
                    border
                    max-height="650"
                    :default-sort = "{prop: 'createDate', order: 'descending'}"
                    style="width: 99%">
                  <el-table-column prop="proceedsName" label="收款项目" align="center"></el-table-column>
                  <el-table-column prop="spareMoney" label="收款金额" align="center"></el-table-column>
                  <el-table-column prop="payee" align="center" label="收款人"></el-table-column>
                  <el-table-column prop="createDate" align="center" label="收款时间"></el-table-column>
                  <el-table-column prop="payment" align="center" label="收款方式"></el-table-column>
                </el-table>
              </div>
              <br>
            </el-form>
            <div v-show="active===2" style="text-align: center">
              <el-upload
                  class="avatar-uploader"
                  action="#"
                  :show-file-list="false"
                  :auto-upload="false"
                  :on-change="handleAvatarChangeIcon">
                <img v-if="imageUrl" :src="imageUrl" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
              <div style="margin-top: 30px">
                <el-button @click="previous">上一步</el-button>
                <el-button type="primary" @click="next">下一步</el-button>
              </div>
            </div>
            <div v-show="active === 3" style="width:500px;margin-left: 240px">
              <el-row class="orderConfirm">
                <el-col :offset="10">
                  客户名：&nbsp &nbsp{{app.name}}
                </el-col>
                <el-col :offset="10">
                  手机号：&nbsp &nbsp{{app.phone}}
                </el-col>
                <el-col :offset="10">
                  订单编号：&nbsp &nbsp{{step1.orderNo}}
                </el-col>
                <el-col :offset="10">
                  订单项目：&nbsp &nbsp{{temp}}
                </el-col>
                <el-col :offset="10">
                  订单总价：&nbsp &nbsp{{step2.orderPrice}}
                </el-col>
                <el-col :offset="10">
                  收款金额：&nbsp &nbsp{{step2.spareMoney}}
                </el-col>
              </el-row>
              <div style="text-align: center;margin-top: 20px">
                <el-button @click="previous">上一步</el-button>
                <el-button type="primary" @click="orderCommit">确认入款</el-button>
              </div>
            </div>
            <div v-show="active === 4">
              <el-result icon="success" title="订单录入成功">
                <template slot="extra">
                  <el-button type="primary" size="medium" @click="toOrderList">查看订单</el-button>
                </template>
              </el-result>
            </div>
          </div>
        </el-scrollbar>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "order-add",
  created() {
    this.pageInit()
    console.log(this.app)
  },
  props: {
    app:{
      type: Object,
      required: true,
      default () {
        return {}
      }
    },
    orderAddState:{
      type: Boolean,
      required: true,
      default: false,
    }
  },
  data() {
   return {
     state: this.orderAddState,
     active: 0,
     tenantCrop: localStorage.getItem("tenantCrop"),
     step1: {
       // 订单日期
       createDate: this.$dateUtils.dateToYMDString(new Date()),
       // 精确婚期
       weddingDay: "",
       // 订单号
       orderNo: "",
       // 订单项目
       orderName: "",
       // 订单备注
       orderRemark: "",
       // 礼服师
       orderDress: this.app.appointDressId,
       // 辅销
       orderAuxiliaryDress: "",
       // 化妆师
       orderCosmetics: this.app.appointCosmeticsId,
     },
     step2: {
       proceedsName: "",
       payment: "",
       orderPrice: "",
       spareMoney: "",
       orderSpare: "",
       payee: "",
       receivedMoney: "",
     },
     step3: {
       fileName: "",
       file: {},
     },
     step1Rules: {
       createDate: [{required: true, message: '请选择订单日期', trigger: 'change'}],
       weddingDay: [{required: true, message: '请选择精确婚期', trigger: 'change'}],
       orderName: [{required: true, message: '请选择订单项目', trigger: 'change'}],
       orderDress: [{required: true, message: '请选择礼服师', trigger: 'change'}],
       orderCosmetics: [{required: true, message: '项目为婚礼跟妆时必填', trigger: 'change'}],
     },
     step2Rules: {
       proceedsName: [{required: true, message: '请选择收款项目', trigger: 'change'}],
       payment: [{required: true, message: '请选择收款方式', trigger: 'change'}],
       orderPrice: [{required: true, message: '请输入订单总价', trigger: 'blur'}],
       spareMoney: [{required: true, message: '请输入收款金额', trigger: 'blur'}],
       payee: [{required: true, message: '请选择收款人', trigger: 'change'}],
     },
     orderDressArray: [],
     orderNameArray: [],
     cosmeticsArray: [],
     proceedsNameArray: [],
     paymentArray: [],
     payeeArray: [],
     weddingList:[],
     // 线上订单收款
     onlineOrderArray: [],
     onlineOrderProceedsArray: [],
     orderIds: [],
     imageUrl: "",
     temp: "",
     //化妆师动态必填
     orderCosmeticsCheck:false,
   }
  },
  watch: {
    'step2.orderPrice'(valOne) {
      this.step2.orderSpare = valOne - this.step2.spareMoney - this.step2.receivedMoney;
    },
    'step2.spareMoney'(valOne) {
      this.step2.orderSpare = this.step2.orderPrice - valOne - this.step2.receivedMoney;
    }
  },
  methods: {
    pageInit() {
      this.$selectUtils.queryDressIds().then(response => {
        this.orderDressArray = JSON.parse(response.data.data);
      })
      this.$selectUtils.queryProjectsIds(this.$projectsType.order).then(response => {
        this.orderNameArray = JSON.parse(response.data.data).filter(item => item.name !== "线上订单");
      })
      this.$selectUtils.queryCosmeticsIds().then(response => {
        this.cosmeticsArray = JSON.parse(response.data.data);
      })
      this.$selectUtils.queryPaymentIds().then(response => {
        this.paymentArray = JSON.parse(response.data.data);
      })
      this.$selectUtils.queryPayeeIds().then(response => {
        this.payeeArray = JSON.parse(response.data.data)
      })
      this.$selectUtils.queryOrderProceedsProjects().then(response => {
        this.proceedsNameArray = JSON.parse(response.data.data);
      })
      this.queryOnlineOrder()
      this.queryWeddingDayByCusId();
    },
    close() {
      this.$emit("close");
    },
    stepNext(step) {
      this.temp = this.$refs.orderName.selectedLabel
      this.$refs[step].validate((valid) => {
        if (valid) {
          this.next()
        }
      })
    },
    previous() {
      this.active--
    },
    next() {
      this.active++
    },
    cascaderClsoe(val) {
      this.step2.payment = val.length===1?val[0]:val.length===2?val[1]:this.step2.payment
      this.$refs.payment.dropDownVisible = false;
    },
    // 查询线上订单
    async queryOnlineOrder() {
      const response = await this.$axios({
        method: "GET",
        url: "/order/queryOnlineOrderByCusId",
        params: {
          cusId: this.app.cusId,
          tenantCrop: this.tenantCrop
        }
      })
      this.onlineOrderArray = response.data.data
      if (this.onlineOrderArray.length == 0 )
        return
      for (let order of this.onlineOrderArray) {
        this.orderIds.push(order.id)
      }
      let temp = this.onlineOrderArray[this.onlineOrderArray.length - 1]
      
      this.queryOnlineOrderProceeds()
      this.step1.orderNo = temp.orderNo
      this.step1.weddingDay = temp.weddingDay
      this.step2.orderPrice = temp.orderPrice
      this.step2.orderSpare = temp.orderSpare
    },
    //查询线上订单收款
    queryOnlineOrderProceeds: function () {
      this.$axios({
        method: "POST",
        url:"/proceeds/queryOnlineOrderProceeds",
        params: {
          orderIds:this.orderIds.toString()
        }
      }).then(response => {
        this.onlineOrderProceedsArray = response.data.data
        let money = 0;
        // 计算已收金额
        for (let temp of this.onlineOrderProceedsArray) {
          money += Number(temp.spareMoney)
        }
        this.step2.receivedMoney = money
        this.step2.orderSpare = (this.step2.orderPrice - money) < 0 ? null : this.step2.orderPrice - money
      })
    },
    handleAvatarChangeIcon(file) {
      this.step3.file = file
      this.imageUrl = URL.createObjectURL(file.raw)
    },
    //订单录入
    orderCommit() {
      let data = {
        ...this.step1,
        ...this.step2,
        tenantCrop: this.tenantCrop,
        orderCity: this.app.appointCity,
        cusId: this.app.cusId,
        appId: this.app.id,
        shopId: this.app.shopId
      }
      this.uploadOrderImage().then(value => {
        if (!value) {
          this.$message({
            showClose: true,
            message: "订单图片上传发生错误,请检查后进行上传",
            type: 'error',
            duration: 1500,
          });
        } else {
          data.orderImage = this.step3.fileName
          data.imageType = this.step1.orderName
          if (this.onlineOrderArray.length > 0) { //在当前线上订单上追加收款
            this.$axios({
              method: "POST",
              url: "/order/updateOnlineOrderToOrder",
              params: {
                id: this.onlineOrderArray[this.onlineOrderArray.length - 1].id,
                ...data,
              }
            }).then(response => {
              if (response.data.code !== 200) {
                this.$message({
                  showClose: true,
                  message: response.data.msg,
                  type: 'error',
                  duration: 1500,
                });
              } else {
                this.next()
              }
            })
          } else {  //录入新订单
            this.$axios({
              method: "POST",
              url: "/order/saveOrder",
              params: {...data}
            }).then(response => {
              if (response.data.code !== 200) {
                this.$message({
                  showClose: true,
                  message: response.data.msg,
                  type: 'error',
                  duration: 1500,                });
                return
              } else {
                this.next()
              }
            })
          }
        }
      });
    },
    //订单项目切换
    orderNameChange(val){
      let chooseArr=this.orderNameArray.filter(item=>item.value===val);
      //如果订单项目是婚礼跟妆则化妆师必填
      this.orderCosmeticsCheck=chooseArr[0].name==='婚礼跟妆';
    },
    uploadOrderImage: function () {
      return new Promise((resolve, reject) => {
        if (this.imageUrl) {
          this.$upload.orderImageUpload(this.step3.file.raw)
              .then(response => {
                let data = response.data
                if (data.code === 200) {
                  this.step3.fileName = data.data
                  resolve(true)
                } else {
                  reject(false)
                }
              })
        } else {
          resolve(true)
        }
      })
    },
    toOrderList() {
      this.$router.push("/work/order/order-list")
    },
    //根据客资Id查询婚期
    queryWeddingDayByCusId() {
      this.$axios({
        method: "GET",
        url: "/weddingDate/queryWeddingDayByCusId",
        params: {
          cusId: this.app.cusId,
        }
      }).then(response => {
        this.weddingList=response.data.data;
        if (this.weddingList.length!==0){
          this.step1.weddingDay=this.weddingList[0].weddingDay
        }
      })
    },
  }
}
</script>

<style>
.el-step.is-simple:not(:last-of-type) .el-step__title {
  max-width: 100%;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
<style scoped>
.el-dialog-div{
  max-height: 51vh;
  overflow: auto;
}
.orderConfirm div{
  margin-top: 15px;
}
</style>